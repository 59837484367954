import * as React from 'react';
import ContentCopy from '@mui/icons-material/ContentCopy';

import { CircularProgress, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';

import { QRCodeSVG } from 'qrcode.react';

import {
  Div28,
  Div37,
  Div38,
  StyledBox,
  StyledButtonContent,
  StyledButtonTryAgain,
  StyledContentError,
  StyledContentLoading,
  StyledContentQrcode,
  StyledPixInfoContent,
} from './styles';
import useAuthenticate from '../../../../recoil/hooks/authenticate';
import { generatePixRequest } from '../../../../api/requestV2';
import {
  calcRemainTime,
  calculateRemainingPercentage,
  isRemainTimeExpired,
} from '../../../../utils/date';
import { LocationData, StatusPaymentRequest } from '../..';
import { AccountData } from '../../StepOne';

interface Props {
  value: number;
  location: LocationData;
  accountData: AccountData[];
  checkPaymentHasBeenPaid: (id: string) => Promise<StatusPaymentRequest>;
}

interface PixData {
  expiresAt: Date;
  createdAt: Date;
  id: string;
  txid: string;
  qrcode: string;
}

const PixPayment: React.FC<Props> = ({
  value,
  location,
  accountData,
  checkPaymentHasBeenPaid,
}): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const [remainTime, setRemainTime] = React.useState('');
  const [percent, setPercent] = React.useState(100);

  const pollingInterval = 15000;

  const [isLoadingGenerate, setLoadingGenerate] = React.useState(true);
  const [isErrorGenerate, setErrorGenerate] = React.useState(false);
  const [isPixHasBeenPaid, setPixHasBeenPaid] = React.useState(false);
  const [isTimeExpired, setTimeExpired] = React.useState(false);
  const [pixData, setPixData] = React.useState<PixData | null>(null);

  const generatePixData = React.useCallback(
    async (value: number) => {
      setPixData(null);
      setLoadingGenerate(true);
      setErrorGenerate(false);
      setTimeExpired(false);
      setPixHasBeenPaid(false);
      try {
        const result = await generatePixRequest(authenticate?.token, {
          value,
          accountData,
          code: authenticate.user.code,
          location,
        });
        setPixData(result?.data);
      } catch (erro) {
        setErrorGenerate(true);
      } finally {
        setLoadingGenerate(false);
      }
    },
    [accountData, authenticate?.token, authenticate.user.code, location]
  );

  React.useEffect(() => {
    if (value) {
      generatePixData(value);
    }
  }, [generatePixData, value]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (pixData?.expiresAt) {
      setRemainTime(calcRemainTime(pixData.expiresAt));
      setTimeExpired(isRemainTimeExpired(pixData.expiresAt, 15));
      interval = setInterval(() => {
        setRemainTime(calcRemainTime(pixData.expiresAt));
        setTimeExpired(isRemainTimeExpired(pixData.expiresAt, 15));
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [pixData?.expiresAt]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (pixData?.expiresAt && pixData?.createdAt) {
      setPercent(
        calculateRemainingPercentage(pixData.createdAt, pixData.expiresAt)
      );

      interval = setInterval(() => {
        setPercent(
          calculateRemainingPercentage(pixData.createdAt, pixData.expiresAt)
        );
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [pixData?.expiresAt, pixData?.createdAt]);

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (pixData?.id) {
      interval = setInterval(async () => {
        const result = await checkPaymentHasBeenPaid(pixData?.id);

        if (result === 'hasBeenPaid') {
          setPixHasBeenPaid(true);
        }
      }, pollingInterval);
    }
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pixData?.id]);

  const handleCopy = () => {
    navigator.clipboard.writeText(pixData?.qrcode || '');
    toast('Pix copiado com sucesso!', {
      type: 'success',
    });
  };

  if (isErrorGenerate) {
    return (
      <StyledContentError>
        <div>Oops!</div>
        <div>Não foi possível gerar o QR Code para pagamento!</div>
        <StyledButtonTryAgain onClick={() => generatePixData(value)}>
          Tentar novamente
        </StyledButtonTryAgain>
      </StyledContentError>
    );
  }

  if (isTimeExpired || isPixHasBeenPaid) {
    return (
      <StyledContentError>
        <div>Oops!</div>
        <div>Parece que esse pagamento expirou, gere um novo!</div>
        <StyledButtonTryAgain onClick={() => generatePixData(value)}>
          Gerar um novo
        </StyledButtonTryAgain>
      </StyledContentError>
    );
  }

  if (isLoadingGenerate) {
    return (
      <StyledContentLoading>
        <CircularProgress />
        <div>Por favor aguarde, estamos gerando o seu pagamento!</div>
      </StyledContentLoading>
    );
  }

  return (
    <React.Fragment>
      <Div28>
        <div>Pix copia e cola:</div>
      </Div28>
      <StyledBox>{pixData?.qrcode}</StyledBox>

      <StyledButtonContent>
        <Div37 onClick={() => handleCopy()}>
          <Div38>Pix copia e cola</Div38>

          <ContentCopy fontSize="small" />
        </Div37>
      </StyledButtonContent>

      <StyledPixInfoContent>
        <div style={{ width: '100%', marginBottom: 8 }}>
          <LinearProgress value={percent} variant="determinate" />
        </div>
        <div>Prazo: {remainTime || ''}</div>
        <div>Aguardando pagamento...</div>
      </StyledPixInfoContent>

      <StyledContentQrcode>
        {Boolean(pixData?.qrcode) && (
          <QRCodeSVG value={pixData?.qrcode || ''} />
        )}
      </StyledContentQrcode>
    </React.Fragment>
  );
};

export default PixPayment;
