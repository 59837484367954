const removeNonNumeric = (str: string | number) => {
  return String(str).replace(/\D/g, '');
};

export const formatNumber = (value: string | number): string => {
  const valueParsed = Number(removeNonNumeric(value));

  if (Number.isNaN(valueParsed)) {
    return '';
  }

  return `${valueParsed.toLocaleString('pt-BR', {
    minimumFractionDigits: 0,
  })}`;
};

export const formatNumberPercent = (value: string | number): string => {
  let valueParsed = Number(removeNonNumeric(value));

  if (Number.isNaN(valueParsed)) {
    return '';
  }

  if (valueParsed > 150) {
    valueParsed = 150;
  }

  if (valueParsed < 0) {
    valueParsed = 0;
  }

  return `${valueParsed}%`;
};
