import { RequestStatusEnum } from '../pages/HomeV2/utils';
import { LocationData } from '../pages/NewRequestV3';
import { AccountData } from '../pages/NewRequestV3/StepOne';
import api from '../services/api';

const downloadFile = (data: any, typeFile: string, filename: string) => {
  const myBlob = new Blob([data], { type: typeFile });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(myBlob);

  link.setAttribute('download', filename); // Added Line
  link.click();
};

export const validateAccountData = async (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  token: string,
  code: string
): Promise<any> => {
  const result = await api.post(
    `/api/validate-account-data?code=${code}`,
    { ...data },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getLastRequestUser = async (token: string): Promise<any> => {
  const result = await api.get(`/api/last-request-user`, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return result?.data;
};

export const validateAccountSheet = async (
  files: FileList,
  token: string,
  code: string
): Promise<any> => {
  const formData = new FormData();

  // eslint-disable-next-line no-restricted-syntax
  for (const file of Array.from(files)) {
    formData.append('files', file);
  }

  const result = await api.post(
    `/api/validate-account-sheet?code=${code}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getAllRequestByUserV2 = async (
  token: string,
  filterStatus: RequestStatusEnum,
  airlineProgram: string,
  page: number,
  itemsPerPage: number
): Promise<any> => {
  const result = await api.get(
    `/api/v2/requests?status=${filterStatus}&page=${page || '0'}&itemsPerPage=${
      itemsPerPage || ''
    }&airlineProgram=${airlineProgram || ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getRequestDetailsV2 = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.get(`/api/v2/requests/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const deleteRequestV2 = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.delete(`/api/v2/requests/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const updateRequestStatusV2 = async (
  token: string,
  id: string,
  status: string,
  oldStatus: string
): Promise<any> => {
  const result = await api.patch(
    `/api/v2/requests/${id}/status`,
    { status, oldStatus },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const updateRequestsStatus = async (
  startDate: string,
  endDate: string,
  status: string,
  token: string
): Promise<any> => {
  const result = await api.patch(
    `/api/v2/requests/change-status`,
    {
      startDate,
      endDate,
      status,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const exportRequestV2 = async (
  startDate: string,
  endDate: string,
  status: string,
  airlineProgram: string,
  token: string
): Promise<any> => {
  const result = await api.post(
    `/api/v2/export-requests`,
    {
      startDate,
      endDate,
      status,
      airlineProgram,
    },
    {
      responseType: 'arraybuffer', // important
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/*',
      },
    }
  );

  downloadFile(result.data, 'xlsx', `report-${new Date().toISOString()}.xlsx`);
};

export const autocompleteCpfByUser = async (
  cpf: string,
  token: string
): Promise<any> => {
  const result = await api.get(`/api/v2/autocomplete?cpf=${cpf}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const cancelRequest = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.patch(
    `/api/v2/requests/${id}/cancel`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const deleteComment = async (
  token: string,
  id: string,
  commentId: string
): Promise<any> => {
  const result = await api.delete(
    `/api/v2/requests/${id}/comments/${commentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const createComment = async (
  token: string,
  id: string,
  comment: string
): Promise<any> => {
  const result = await api.post(
    `/api/v2/requests/${id}/comments`,
    {
      comment,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const generatePixRequest = async (
  token: string,
  data: {
    value: number;
    accountData: AccountData[];
    location: LocationData;
    code: string;
  }
): Promise<any> => {
  const result = await api.post(`/api/pix`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export interface CheckPixData {
  id: string;
  accounts: AccountData[];
}

export const checkPaymentHasBeenPaidRequest = async (
  data: CheckPixData,
  token: string,
  code?: string,
  location?: {
    latitude: number | null;
    longitude: number | null;
  }
): Promise<any> => {
  const result = await api.post(
    `/api/check-pix`,
    {
      ...data,
      code,
      location,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};
